import React, {Component} from "react";
import config from "../config";

export default class EmployeeDetail extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const employee = this.props.employee;
        return (
            <div className="card">
                <h5 className="card-header">HMS-kort</h5>
                <div className="row no-gutters">
                    <div className="col-md-8">
                        <div className="card-body">
                            <p className="card-text">Navn: {employee.userName}</p>
                            <p className="card-text">Tlf: {employee.userPhone}</p>
                            <p className="card-text">Firma: {config.employeeDetailCompany}</p>
                            {!employee.isActive && <p className="card-text">OBS: Ikke lenger aktiv.</p>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={"/user-" + employee.userPhone + ".png"} className="card-img img-thumbnail rounded" alt="Employee picture"/>
                    </div>
                </div>
            </div>
        );
    }
}
