import React, {Component} from "react";
import {API} from "aws-amplify";
import TableExpandable from "./TableExpandable";

export default class History extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            items: [],
            users: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const items = await this.items();
            const users = await this.users();
            this.setState({items: this.handleItems(items, users)});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    items() {
        return API.get("admin", "/items/scan");
    }

    users() {
        return API.get("admin", "/users/scan");
    }

    handleItems(items, users) {
        // console.log(items);
        // console.log(users);
        const userMap = {};
        users.forEach(user => {
            userMap[user.userId] = user
        });
        items.forEach(item => {
            item.user = userMap[item.userId];
        });
        return items;
    }

    renderItems() {
        return (
            <div className="items">
                <h1>Alle kjøp</h1>
                <div>
                    {this.state.isLoading && <i className="fas fa-spinner fa-spin"/>}
                    {!this.state.isLoading && <TableExpandable data={this.state.items} addItemHandledCol/>}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="content shadow">
                {this.renderItems()}
            </div>
        );
    }
}