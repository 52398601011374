import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {API} from "aws-amplify";

export default class NewEmployee extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            // userName: "",
            userPhone: "",
            userPin: "",
            error: null
        };
    }

    validateForm() {
        return this.state.userPhone.length > 0 && this.state.userPin.length > 0;
    }

    isUsernameExistsException(err) {
        return err && err.data && err.data.code === "UsernameExistsException";
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true, error: null});

        try {
            await this.createItem({
                // userName: this.state.userName,
                userPhone: this.state.userPhone,
                userPassword: 'VK' + this.state.userPin
            });
            this.props.history.push("/ansatte");
        } catch (e) {
            console.log(e);
            console.log(e.response);
            if (!this.isUsernameExistsException(e.response)) {
                alert(e);
            }
            this.setState({isLoading: false, error: e.response});
        }
    };

    createItem(item) {
        return API.post("admin", "/users", {
            body: item
        });
    }

    renderErrorMsg() {
        if (this.isUsernameExistsException(this.state.error)) {
            return (
                <div className="alert alert-danger mt-2" role="alert">
                    Telefonnummeret som er tastet inn er allerede i bruk. Vennligst velg et annet telefonnummer.
                </div>
            )
        }
    }

    render() {
        return (
            <div className="NewItem">
                <h1>Legg til ansatt</h1>
                <form onSubmit={this.handleSubmit}>
                    {/*<div className="form-group">*/}
                        {/*<label htmlFor="userName">Navn</label>*/}
                        {/*<input type="text" className="form-control" id="userName"*/}
                               {/*value={this.state.userName} autoFocus={true}*/}
                               {/*onChange={this.handleChange}/>*/}
                    {/*</div>*/}
                    <div className="form-group">
                        <label htmlFor="userPhone">Telefon</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">+47</div>
                            </div>
                            <input type="number" className="form-control form-control-lg" id="userPhone"
                                   value={this.state.userPhone}
                                   onChange={this.handleChange}/>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="userName">Passord</label>
                        <div className="input-group mb-2">
                            <div className="input-group-prepend">
                                <div className="input-group-text">VK</div>
                            </div>
                            <input type="number" className="form-control form-control-lg" id="userPin"
                                   value={this.state.userPin} onChange={this.handleChange}/>
                        </div>
                        <small id="pinHelp" className="form-text text-muted">Passordet må inneholde minimum 4
                            tegn/siffer.
                        </small>
                    </div>
                    {this.renderErrorMsg()}
                    <div className="btn-group mr-2" role="group">
                        <LoaderButton
                            block
                            bsStyle="primary"
                            bsSize="large"
                            disabled={!this.validateForm()}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="Opprett"
                            loadingText="Vennligst vent…"
                        />
                    </div>
                    <div className="btn-group" role="group">
                        <LoaderButton
                            block
                            bsSize="large"
                            className="btn-danger"
                            disabled={this.state.isLoading}
                            text="Avbryt"
                            type="button"
                            onClick={() => this.props.history.push("/ansatte")}
                        />
                    </div>
                </form>
            </div>
        );
    }
}
