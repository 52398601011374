import React, {Component} from "react";
import moment from "moment";

export default class TableExpandableRow extends Component {
    addExpandedBtn() {
        return (
            <td>
                <button className={"btn btn-outline-dark btn-sm"} onClick={this.props.clickCallback}>
                    <i className={"fal fa-chevron-" + (this.props.expanded ? 'up' : 'down')}/>
                </button>
            </td>
        );
    }

    render() {
        const item = this.props.item;
        const cols = [
            <td>{moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss")}</td>,
            <td>{item.speedyId}</td>,
            <td>{item.reqCode}</td>,
            <td>{item.user && item.user.userName}</td>,
            <td>
                    <span className="d-inline-block text-truncate" style={{maxWidth: "150px"}}>
                      {item.content.trim().split("\n")[0]}
                    </span>
            </td>,
            <td>{item.attachment && <i className="fal fa-check"/>}</td>
        ];
        if (this.props.addItemHandledCol) {
            cols.push(<td>{item.itemHandled && <i className="fal fa-check"/>}</td>);
        }
        cols.push(this.addExpandedBtn());
        return (
            <tr onClick={this.props.clickCallback} key={"row-data-" + item.itemId}
                className={"table-expandable-row table-expandable-row-transition " + (item.hideRowTransition ? 'hide-row-transition' : '')}>
                {cols}
            </tr>
        );
    }
}