import React, {Component} from "react";
import {API, Storage} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import EmployeeDetail from "./EmployeeDetail";
import moment from "moment";
import config from "../config";

export default class TableExpandableRowExpanded extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: true,
            item: null,
            attachmentURL: null,
            isUpdating: false
        };
    }

    async componentDidMount() {
        try {
            let attachmentURL;
            const item = this.props.item;
            const {attachment} = item;

            if (attachment) {
                attachmentURL = await Storage.vault.get(attachment, {
                    level: 'private',
                    identityId: item.userId
                });
            }
            this.setState({
                item,
                attachmentURL
            });
        } catch (e) {
            alert(e);
        }
        this.setState({isLoading: false});
    }

    formatFilename(str) {
        return str.replace(/^\w+-/, "");
    }

    renderContent(content) {
        return content.split('\n').map(line => {
            return <p className="card-text">{line}</p>;
        });
    }

    handleHandledSubmit = async event => {
        event.preventDefault();

        this.setState({isUpdating: true});

        try {
            await this.updateItem({userId: this.props.item.userId});
            this.props.item.hideRowTransition = true;
            this.props.handleUpdateHandledClick(this.props.item);
        } catch (e) {
            alert(e);
            this.setState({isUpdating: false});
        }
    };

    updateItem(item) {
        return API.put("admin", `/items/handled/${this.props.item.itemId}`, {
            body: item
        });
    }

    renderSetHandledButton() {
        if (this.props.showHandledButton) {
            return (
                <div className={"mt-2"}>
                    <form onSubmit={this.handleHandledSubmit}>
                        <LoaderButton
                            className="btn-greencolor"
                            type="submit"
                            isLoading={this.state.isUpdating}
                            text="MARKER SOM LEST"
                            loadingText="VENNLIGST VENT…"
                        />
                    </form>
                </div>
            )
        }
    }

    render() {
        const item = this.props.item;
        const colSpan = this.props.addItemHandledCol ? '9' : '8';
        return (
            <tr key={"row-expanded-" + item.itemId}
                className={"table-expandable-row-transition " + (item.hideRowTransition ? 'hide-row-transition' : '')}>
                <td colSpan={colSpan}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h3 className="card-title mb-5">
                                        Fakturanr: <span className="badge badge-secondary"
                                                         style={{padding: "10px"}}>{config.reqPrefix}/{item.speedyId}/{item.reqCode}</span>
                                    </h3>
                                    <h5>Kjøpsbeskrivelse:</h5>
                                    {this.renderContent(item.content)}
                                    {this.state.isLoading &&
                                    <p><i className="fas fa-spinner fa-spin"/> Henter kvittering..</p>}
                                    {!this.state.isLoading && this.state.item && this.state.item.attachment &&
                                    <a target="_blank"
                                       rel="noopener noreferrer"
                                       href={this.state.attachmentURL}>
                                        {this.formatFilename(this.state.item.attachment)}
                                    </a>
                                    }
                                    <div className={"mt-5"}>
                                        <p className={"mb-0"}>Opprettet: {moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")}</p>
                                        <p>Sist oppdatert: {moment(item.updatedAt).format("DD.MM.YYYY HH:mm:ss")}</p>
                                    </div>
                                </div>
                                <div className="col">
                                    <EmployeeDetail employee={item.user}/>
                                </div>
                            </div>
                            {this.renderSetHandledButton()}
                        </div>
                    </div>
                </td>
            </tr>
        );
    }
}