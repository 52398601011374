import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {Auth} from "aws-amplify";

export default class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: "",
            email: "",
            password: "",
            codeSent: false,
            confirmed: false,
            confirmPassword: "",
            isConfirming: false,
            isSendingCode: false
        };
    }

    validateCodeForm() {
        return this.state.email.length > 0;
    }

    validateResetForm() {
        return (
            this.state.code.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleSendCodeClick = async event => {
        event.preventDefault();

        this.setState({isSendingCode: true});

        try {
            await Auth.forgotPassword(this.state.email);
            this.setState({codeSent: true});
        } catch (e) {
            alert(e.message);
            this.setState({isSendingCode: false});
        }
    };

    handleConfirmClick = async event => {
        event.preventDefault();

        this.setState({isConfirming: true});

        try {
            await Auth.forgotPasswordSubmit(
                this.state.email,
                this.state.code,
                this.state.password
            );
            this.setState({confirmed: true});
        } catch (e) {
            alert(e.message);
            this.setState({isConfirming: false});
        }
    };

    renderRequestCodeForm() {
        return (
            <form onSubmit={this.handleSendCodeClick}>
                <div className="form-group">
                    <label htmlFor="email">E-post</label>
                    <input type="email" className="form-control" id="email" value={this.state.email}
                           onChange={this.handleChange}/>
                </div>
                <div className="btn-group" role="group">
                    <LoaderButton
                        block
                        bsSize="large"
                        className="btn-block btn-lg btn-bluecolor"
                        disabled={!this.validateCodeForm()}
                        type="submit"
                        isLoading={this.state.isSendingCode}
                        text="SEND KODE"
                        loadingText="VENNLIGST VENT…"
                    />
                </div>
            </form>
        );
    }

    renderConfirmationForm() {
        return (
            <form onSubmit={this.handleConfirmClick}>
                <div className="form-group">
                    <label htmlFor="code">Bekreftelseskode:</label>
                    <input type="tel" className="form-control" id="code" value={this.state.code}
                           onChange={this.handleChange}/>
                </div>
                <p>Vennligst sjekk din e-post for bekreftelseskode.</p>
                <div className="form-group">
                    <label htmlFor="password">Nytt passord:</label>
                    <input type="password" className="form-control" id="password"
                           value={this.state.password}
                           onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Bekreft passord:</label>
                    <input type="password" className="form-control" id="confirmPassword"
                           value={this.state.confirmPassword}
                           onChange={this.handleChange}/>
                </div>
                <div className="btn-group" role="group">
                    <LoaderButton
                        block
                        bsSize="large"
                        className="btn-block btn-lg btn-bluecolor"
                        disabled={!this.validateResetForm()}
                        type="submit"
                        isLoading={this.state.isConfirming}
                        text="BEKREFT"
                        loadingText="VENNLIGST VENT…"
                    />
                </div>
            </form>
        );
    }

    renderSuccessMessage() {
        return (
            <div className="success">
                <p><i className="fas fa-thumbs-up"/> Ditt passord er nå endret.</p>
                <LoaderButton
                    block
                    bsSize="large"
                    className="btn-block btn-lg btn-bluecolor"
                    disabled={this.state.isLoading}
                    text="Trykk her for å logge inn"
                    type="button"
                    onClick={() => this.props.history.push("/login")}
                />
            </div>
        );
    }

    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-4">
                    <div className="content shadow">
                        <h4>Glemt passord?</h4>
                        {!this.state.codeSent
                            ? this.renderRequestCodeForm()
                            : !this.state.confirmed
                                ? this.renderConfirmationForm()
                                : this.renderSuccessMessage()}
                    </div>
                </div>
            </div>
        );
    }
}
