import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {Auth} from "aws-amplify";

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            showErrorMsg: false,
            email: "",
            password: ""
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
            showErrorMsg: false
        });
    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const user = await Auth.signIn(this.state.email, this.state.password);
            this.props.userHasAuthenticated(true, user);
        } catch (e) {
            console.log(e);
            if (e.code && (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException')) {
                this.setState({
                    showErrorMsg: true,
                    password: '',
                    isLoading: false
                });
            } else {
                alert(e.message);
                this.setState({isLoading: false});
            }
        }
    };

    renderErrorMsg() {
        if (this.state.showErrorMsg) {
            return (
                <div className="alert alert-danger mt-2" role="alert">
                    Feil brukernavn/passord. Vennligst prøv igjen.
                </div>
            )
        }
    }

    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-6">
                    <div className="Login content shadow">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="email">E-post</label>
                                <input type="email" className="form-control" id="email" value={this.state.email}
                                       onChange={this.handleChange}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Passord</label>
                                <input type="password" className="form-control" id="password"
                                       value={this.state.password}
                                       onChange={this.handleChange}/>
                            </div>
                            {this.renderErrorMsg()}
                            <div className="btn-group mr-2" role="group">
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    className="btn-block btn-lg btn-bluecolor"
                                    disabled={!this.validateForm()}
                                    type="submit"
                                    isLoading={this.state.isLoading}
                                    text="LOGG INN"
                                    loadingText="VENNLIGST VENT…"
                                />
                            </div>
                            <div className="btn-group" role="group">
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    className="btn-block btn-lg btn-bluecolor"
                                    disabled={this.state.isLoading}
                                    text="GLEMT PASSORD?"
                                    type="button"
                                    onClick={() => this.props.history.push("/glemt-passord")}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
