import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import {API} from "aws-amplify";
import {s3Upload} from "../libs/awsLib";
import config from "../config";

export default class NewNote extends Component {
    constructor(props) {
        super(props);

        this.file = null;

        this.state = {
            isLoading: null,
            content: ""
        };
    }

    validateForm() {
        return this.state.content.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    };

    handleFileChange = event => {
        this.file = event.target.files[0];
    };

    handleSubmit = async event => {
        event.preventDefault();

        if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
            alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        this.setState({isLoading: true});

        try {
            const attachment = this.file ? await s3Upload(this.file) : null;

            await this.createItem({
                attachment,
                content: this.state.content
            });
            this.props.history.push("/");
        } catch (e) {
            alert(e);
            this.setState({isLoading: false});
        }
    };

    createItem(item) {
        return API.post("nuh", "/items", {
            body: item
        });
    }

    render() {
        return (
            <div className="NewItem">
                <form onSubmit={this.handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="content">Innhold</label>
                        <textarea className="form-control" id="content" value={this.state.content}
                                  onChange={this.handleChange}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="file">Vedlegg</label>
                        <input type="file" className="form-control" id="file"
                               onChange={this.handleFileChange}/>
                    </div>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Opprett"
                        loadingText="Vennligst vent…"
                    />
                </form>
            </div>
        );
    }
}
