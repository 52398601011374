import React from "react";
import {Route, Switch} from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import NewItem from "./containers/NewItem";
import NewEmployee from "./containers/NewEmployee";
import Employees from "./containers/Employees";
import Business from "./containers/Business";
import NewItemDev from "./containers/NewItemDev";
import Projects from "./containers/Projects";
import History from "./containers/History";
import Profile from "./containers/Profile";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";

export default ({childProps}) =>
    <Switch>
        <AuthenticatedRoute path="/" exact component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps}/>
        <UnauthenticatedRoute path="/glemt-passord" exact component={ResetPassword} props={childProps}/>
        <AuthenticatedRoute path="/items/new" exact component={NewItem} props={childProps}/>
        <AuthenticatedRoute path="/rekvisisjon/ny" exact component={NewItemDev} props={childProps}/>
        <AuthenticatedRoute path="/ansatte/ny" exact component={NewEmployee} props={childProps}/>
        {/*<AuthenticatedRoute path="/items/:id" exact component={Items} props={childProps} />*/}
        <AuthenticatedRoute path="/ansatte" exact component={Employees} props={childProps}/>
        <AuthenticatedRoute path="/virksomhet" exact component={Business} props={childProps}/>
        <AuthenticatedRoute path="/prosjekter" exact component={Projects} props={childProps}/>
        <AuthenticatedRoute path="/historikk" exact component={History} props={childProps}/>
        <AuthenticatedRoute path="/profil" exact component={Profile} props={childProps}/>
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;