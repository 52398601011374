import React, {Component} from "react";
import {Link, withRouter} from "react-router-dom";
import Routes from "./Routes";
import {Auth} from "aws-amplify";
import config from "./config";
import "./App.css";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true,
            userEmail: ''
        };
    }

    async componentDidMount() {
        try {
            const user = await Auth.currentSession();
            this.userHasAuthenticated(true, user);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        this.setState({isAuthenticating: false});
    }

    userHasAuthenticated = (authenticated, user) => {
        let userEmail = '';
        if (user && user.idToken && user.idToken.payload) {
            userEmail = user.idToken.payload.email;
        } else if (user && user.attributes) {
            userEmail = user.attributes.email;
        }
        this.setState({isAuthenticated: authenticated, userEmail: userEmail});
    };

    handleLogout = async event => {
        await Auth.signOut();

        this.userHasAuthenticated(false, null);

        this.props.history.push("/login");
    };

    menuItemClicked = (url) => {
        this.props.history.push(url);
    };

    menuItem = (text, url, iconName) => {
        let color = "bgGrayColor redColor";
        if (this.props.location.pathname === url) {
            color = "bgBlueColor";
        }
        const icon = <i className={"fal fa-" + iconName}/>;
        return (
            <div className="list-group mt-1">
                <button className={"list-group-item btn-menu list-group-item-action " + color}
                        onClick={() => this.menuItemClicked(url)}>
                    {icon} {text}
                </button>
            </div>
        );
    };

    renderLeftCol() {
        return (
            <div>
                <div className="list-group mt-1 mb-3">
                    <button className={"list-group-item list-group-item-action bgRedColor btn-menu"}
                            onClick={() => this.menuItemClicked("/rekvisisjon/ny")}>
                        <i className={"fal fa-plus-square"}/> Opprett ny rekvisisjon
                    </button>
                </div>
                {this.menuItem("Vis nye kjøp", "/", "exclamation-triangle")}
                {this.menuItem("Vis alle kjøp", "/historikk", "history")}
                {this.menuItem("Virksomhet", "/virksomhet", "cog")}
                {this.menuItem("Ansatte", "/ansatte", "user-cog")}
                {/*{this.menuItem("Prosjekter", "/prosjekter", "project-diagram")}*/}
                {/*{this.menuItem("Min profil", "/profil")}*/}
            </div>
        );
    }

    renderVersionBar() {
        if (process.env.REACT_APP_STAGE === 'test') {
            return (
                <div className="alert alert-warning mb-0 text-center" role="alert">
                    TEST - ADMIN
                </div>
            )
        }
    }

    renderUserInfo() {
        return (
            <li className="nav-item">
                <span className="navbar-text">
                {this.state.userEmail}
                </span>
            </li>
        )
    }

    renderFooter() {
        return (
            <div className="alert alert-light mt-5 text-center alert-footer" role="alert">
                <span className="navbar-text">v0.1</span>
            </div>
        )
    }

    renderNavbar() {
        return (
            <nav className="navbar shadow sticky-top navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand mr-auto" href="/">
                    <img src="/logo_farger.svg" height="30" alt="NUH! Logo"/>
                    <span className={"ml-2"}>Admin</span>
                </a>
                <div className="mr-auto">
                    {this.state.isAuthenticated && <span className={"ml-2"}>{config.companyTitle}</span>}
                </div>
                <div>
                    {this.state.isAuthenticated &&
                    <ul className="navbar-nav">
                        {this.renderUserInfo()}
                        <li className="nav-item">
                            <button className="btn nav-link btn-sm btn-outline-secondary ml-2"
                                    onClick={this.handleLogout}>LOGG UT
                            </button>
                        </li>
                    </ul>
                    }
                </div>
            </nav>
        );
    }

    renderContent(childProps) {
        if (this.state.isAuthenticated) {
            return (
                <div className="mr-3 ml-3">
                    <div className="row mt-2">
                        <div className="col-md-3 col-lg-2">
                            {this.renderLeftCol()}
                        </div>
                        <div className="col">
                            <Routes childProps={childProps}/>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="row mt-2">
                <div className="col">
                    <Routes childProps={childProps}/>
                </div>
            </div>
        );
    }

    render() {
        const childProps = {
            isAuthenticated: this.state.isAuthenticated,
            userHasAuthenticated: this.userHasAuthenticated
        };
        return (
            !this.state.isAuthenticating &&
            <div>
                <div className="container-fluid">
                    {this.renderVersionBar()}
                    {this.renderNavbar()}
                </div>
                <div className="container-fluid mt-5">
                    {this.renderContent(childProps)}
                </div>
            </div>
        );
    }
}

export default withRouter(App);

