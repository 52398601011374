import React, {Component} from "react";
import TableExpandableRow from "./TableExpandableRow";
import TableExpandableRowExpanded from "./TableExpandableRowExpanded";
import config from "../config";

export default class TableExpandable extends Component {
    constructor() {
        super();

        this.state = {
            expandedRows: [],
            sortConfig: {
                key: 'lastUpdated',
                direction: 'ascending'
            }
        };
    }

    setSortedField(field) {
        let direction = 'ascending';
        if (this.state.sortConfig.key === field && this.state.sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        this.setState({
            expandedRows: [],
            sortConfig: {
                key: field,
                direction: direction
            }
        });
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

        const newExpandedRows = isRowCurrentlyExpanded ?
            currentExpandedRows.filter(id => id !== rowId) :
            currentExpandedRows.concat(rowId);

        this.setState({expandedRows: newExpandedRows});
    }

    renderItem(item) {
        const clickCallback = () => this.handleRowClick(item.itemId);
        const expanded = this.state.expandedRows.includes(item.itemId);
        const itemRows = [<TableExpandableRow item={item} clickCallback={clickCallback}
                                              addItemHandledCol={this.props.addItemHandledCol} expanded={expanded}/>];

        if (expanded) {
            itemRows.push(<TableExpandableRowExpanded key={item.itemId} item={item}
                                                      showHandledButton={this.props.showHandledButton}
                                                      handleUpdateHandledClick={(item) => this.props.removeItemFromList(item)}/>);
        }

        return itemRows;
    }

    sortFunction(a, b) {
        let sortConfig = this.state.sortConfig;
        let sortedField = sortConfig.key;
        let sortedDirection = sortConfig.direction;
        let upper = sortedDirection === 'ascending' ? -1 : 1;
        let lower = sortedDirection === 'ascending' ? 1 : -1;
        if (sortedField === 'lastUpdated') {
            if (sortedDirection === 'ascending') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            }
            return new Date(a.createdAt) - new Date(b.createdAt);
        }
        if (sortedField === 'speedyId' || sortedField === 'reqCode' || sortedField === 'content') {
            if (a[sortedField] < b[sortedField]) {
                return upper;
            }
            if (a[sortedField] > b[sortedField]) {
                return lower;
            }
            return 0;
        }
        if (sortedField === 'userName') {
            if (!a.user) {
                return upper;
            }
            if (!b.user) {
                return lower;
            }
            if (a.user.userName < b.user.userName) {
                return upper;
            }
            if (a.user.userName > b.user.userName) {
                return lower;
            }
            return 0;
        }
        if (sortedField === 'attachment' || sortedField === 'itemHandled') {
            if (a[sortedField]) {
                return upper;
            }
            if (b[sortedField]) {
                return lower;
            }
            return 0;
        }
        return 0;
    }

    getSortChevron(field) {
        let sortConfig = this.state.sortConfig;
        if (sortConfig.key === field) {
            if (sortConfig.direction === 'ascending') {
                return <i className="far fa-chevron-down ml-1"/>;
            }  else {
                return <i className="far fa-chevron-up ml-1"/>;
            }
        }
    }

    render() {
        if (this.props.data.length > 0) {
            let allItemRows = [];

            let sortedData = [...this.props.data];

            sortedData
                .sort((a, b) => this.sortFunction(a, b))
                .forEach(item => {
                    const perItemRows = this.renderItem(item);
                    allItemRows = allItemRows.concat(perItemRows);
                });

            const cols = [
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('lastUpdated')}>
                        <span>Sist oppdatert</span>
                        {this.getSortChevron('lastUpdated')}
                    </button>
                </th>,
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('speedyId')}>
                        <span>{config.projectCodeTitle}</span>
                        {this.getSortChevron('speedyId')}
                    </button>
                </th>,
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('reqCode')}>
                        <span>Referanse</span>
                        {this.getSortChevron('reqCode')}
                    </button>
                </th>,
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('userName')}>
                        <span>Ansatt</span>
                        {this.getSortChevron('userName')}
                    </button>
                </th>,
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('content')}>
                        <span>Kjøpsbeskrivelse</span>
                        {this.getSortChevron('content')}
                    </button>
                </th>,
                <th scope="col">
                    <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('attachment')}>
                        <span>Kvittering</span>
                        {this.getSortChevron('attachment')}
                    </button>
                </th>
            ];

            if (this.props.addItemHandledCol) {
                cols.push(
                    <th scope="col">
                        <button type="button" className={'btn btn-link redColor'} onClick={() => this.setSortedField('itemHandled')}>
                            <span>Lest</span>
                            {this.getSortChevron('itemHandled')}
                        </button>
                    </th>
                );
            }

            cols.push(<th scope="col"/>);

            return (
                <table className="table table-hover table-sm">
                    <thead>
                    <tr>
                        {cols}
                    </tr>
                    </thead>
                    <tbody>
                    {allItemRows}
                    </tbody>
                </table>
            );
        }
        return (
            <div>
                {!this.props.title && "Ingen tidligere kjøp registrert."}
                {this.props.title}
            </div>
        );
    }
}