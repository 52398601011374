import React, {Component} from "react";
import {Auth} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";

export default class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: "",
            password: "",
            confirmPassword: "",
            confirmationCode: "",
            newUser: null
        };
    }

    validateForm() {
        return (
            this.state.email.length > 0 &&
            this.state.password.length > 0 &&
            this.state.password === this.state.confirmPassword
        );
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 0;
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            const newUser = await Auth.signUp({
                username: this.state.email,
                password: this.state.password
            });
            this.setState({
                newUser
            });
        } catch (e) {
            alert(e.message);
        }

        this.setState({isLoading: false});
    }

    handleConfirmationSubmit = async event => {
        event.preventDefault();

        this.setState({isLoading: true});

        try {
            await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
            await Auth.signIn(this.state.email, this.state.password);

            this.props.userHasAuthenticated(true);
            this.props.history.push("/");
        } catch (e) {
            alert(e.message);
            this.setState({isLoading: false});
        }
    };


    renderConfirmationForm() {
        return (
            <div>
                <h3>Takk!</h3>
                <p>Din forespørsel om å bli administrator blir behandlet snarest.</p>
                <p>Vi kommer tilbake til deg når brukeren er klar til bruk.</p>
            </div>
        );
    }

    renderForm() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label htmlFor="email">E-post</label>
                    <input type="email" className="form-control" id="email" value={this.state.email}
                           onChange={this.handleChange}
                           aria-describedby="emailHelp"/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Passord</label>
                    <input type="password" className="form-control" id="password"
                           value={this.state.password}
                           onChange={this.handleChange}/>
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Bekreft passord</label>
                    <input type="password" className="form-control" id="confirmPassword"
                           value={this.state.confirmPassword}
                           onChange={this.handleChange}/>
                </div>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Opprett bruker"
                    loadingText="Vennligst vent…"
                />
            </form>
        );
    }

    render() {
        return (
            <div className="row justify-content-md-center">
                <div className="col-md-6">
                    <div className="Signup content shadow">
                        {this.state.newUser === null
                            ? this.renderForm()
                            : this.renderConfirmationForm()}
                    </div>
                </div>
            </div>
        );
    }
}
