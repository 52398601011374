import React from "react";
import config from "../config";

export default () =>
    <div className="content shadow">
        <h3>Virksomhet</h3>
        <form>
            <div className="form-group">
                <label htmlFor="userName">Rekvisisjon-prefix:</label>
                <div className="input-group mb-2">
                    <input type="text" className="form-control" id="userName" value={config.reqPrefix} readOnly={true}/>
                </div>
            </div>
        </form>
    </div>;