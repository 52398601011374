import React, {Component} from "react";
import {API} from "aws-amplify";

export default class Employees extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            users: []
        };
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const users = await this.users();
            // const cognitoUsers = await this.cognitoUsers();
            // this.setState({users: this.handleUsers(users, cognitoUsers)});
            this.setState({users: users});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    users() {
        return API.get("admin", "/users/scan");
    }

    cognitoUsers() {
        return API.get("admin", "/listUsers");
    }

    handleUsers(users, cognitoUsers) {
        // console.log(users);
        // console.log(cognitoUsers);
        const list = [];
        cognitoUsers.forEach(user => {
            const phone = user.Attributes.find(attr => attr.Name === 'phone_number');
            const existingUser = users.find(u => '+47' + u.userPhone === phone.Value);
            if (!existingUser) {
                list.push({
                    userPhone: phone.Value.replace('+47', ''),
                    userName: '-',
                    waitingForProfileInformation: true
                });
            } else {
                list.push(existingUser);
            }
        });
        return list;
    }

    renderItem(user) {
        return (
            <tr key={"row-data-" + user.userId} style={{fontStyle: user.waitingForProfileInformation ? 'italic' : ''}} className={!user.isActive && 'table-secondary'}>
                <td>{user.userPhone}</td>
                <td>{user.userName}</td>
                <td><img src={"/user-" + user.userPhone + ".png"} style={{maxWidth: '100px'}} className="card-img img-thumbnail rounded" alt="Employee picture"/></td>
                <td>{user.isActive ? 'Aktiv' : 'Ikke aktiv'}</td>
            </tr>
        );
    }

    renderItems() {
        if (this.state.users.length > 0) {
            let allItemRows = [];

            this.state.users
                .sort((a, b) => {
                    return a.userPhone - b.userPhone;
                })
                .forEach(item => {
                    allItemRows.push(this.renderItem(item));
                });

            return (
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th scope="col">Telefon</th>
                        <th scope="col">Navn</th>
                        <th scope="col">HMS-kort</th>
                        <th scope="col">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {allItemRows}
                    </tbody>
                </table>
            );
        }
        return (
            <div>
                Ingen ansatte registrert.
            </div>
        );
    }

    render() {
        return (
            <div className="content shadow">
                <div className="items">
                    <h1>Ansatte</h1>
                    <div>
                        {this.state.isLoading && <i className="fas fa-spinner fa-spin"/>}
                        {!this.state.isLoading && this.renderItems()}
                    </div>
                </div>
            </div>
        );
    }
}