const test = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads-test"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://2xclu0fnte.execute-api.eu-central-1.amazonaws.com/test"
    },
    adminApiGateway: {
        REGION: "eu-central-1",
        URL: "https://524ygbo92b.execute-api.eu-central-1.amazonaws.com/test"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_VLDTSxhN4",
        APP_CLIENT_ID: "f4hcb8rbovt6486qfd47q0me",
        IDENTITY_POOL_ID: "eu-central-1:cdf980a3-8dd4-4339-9e78-2bb6cf872731"
    },
    companyTitle: 'Firmanavn (TEST)',
    projectCodeTitle: 'Prosjektkode',
    employeeDetailCompany: 'Firmanavn (TEST)',
    reqPrefix: 'XXX'
};

const prod = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://x1aa9s9fsb.execute-api.eu-central-1.amazonaws.com/prod"
    },
    adminApiGateway: {
        REGION: "eu-central-1",
        URL: "https://h4jibbh0k5.execute-api.eu-central-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_VLDTSxhN4",
        APP_CLIENT_ID: "f4hcb8rbovt6486qfd47q0me",
        IDENTITY_POOL_ID: "eu-central-1:cdf980a3-8dd4-4339-9e78-2bb6cf872731"
    },
    companyTitle: 'Varanger Kraft Entreprenør',
    projectCodeTitle: 'Speedy-nr',
    employeeDetailCompany: 'VKE',
    reqPrefix: '112008'
};

const sundquist = {
    s3: {
        REGION: "eu-central-1",
        BUCKET: "nuh-uploads-sundquist"
    },
    apiGateway: {
        REGION: "eu-central-1",
        URL: "https://t3l5xrx0s6.execute-api.eu-central-1.amazonaws.com/sundquist"
    },
    adminApiGateway: {
        REGION: "eu-central-1",
        URL: "https://j4899t8sei.execute-api.eu-central-1.amazonaws.com/sundquist"
    },
    cognito: {
        REGION: "eu-central-1",
        USER_POOL_ID: "eu-central-1_Yp4OUYr21",
        APP_CLIENT_ID: "ghm69nta13k57blgdip74l3j6",
        IDENTITY_POOL_ID: "eu-central-1:8225da9d-aecb-43d3-a227-a3418a4887c0"
    },
    companyTitle: 'Sundquist',
    projectCodeTitle: 'Prosjektkode',
    employeeDetailCompany: 'Sundquist',
    reqPrefix: '000'
};

const config = process.env.REACT_APP_STAGE === 'production' ? prod : (process.env.REACT_APP_STAGE === 'sundquist' ? sundquist : test);

export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};