import React from "react";

export default ({
                    isLoading,
                    text,
                    loadingText,
                    className = "",
                    disabled = false,
                    ...props
                }) =>
    <button
        className={`btn btn-primary LoaderButton ${className}`}
        disabled={disabled || isLoading}
        {...props}
    >
        {isLoading && <i className="fas fa-spinner fa-spin mr-2"/>}
        {!isLoading ? text : loadingText}
    </button>;
